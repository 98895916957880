<template>
  <div>
    <h5 class="error--text mb-5">(*) {{ $t('common.mandatoryFields') }}</h5>
    <!--  -->
    <!-- Name Field -->
    <!--  -->
    <v-row
      ><v-col>
        <base-field-input
          v-model="user.attributes.info.name"
          :label="$t('user.contactName')"/></v-col
      ><v-col>
        <base-field-input
          v-model="user.attributes.info.personalId"
          :label="$t('user.personalId')"
          type="text"
          autocomplete="nope"
        /> </v-col
    ></v-row>

    <v-row
      ><v-col cols="12" md="6">
        <base-field-input
          v-model="user.attributes.email"
          :disabled="!!user.id"
          :label="$t('common.email') + '(*)'"
          :hint="$t('hints.emailRequired')"
          persistent-hint
          autocomplete="new-password"
          type="email"
          :rules="emailRules"/></v-col
      ><v-col> <phone-number-input :user="user" /> </v-col
    ></v-row>
    <v-row
      ><v-col v-if="!user.id || user.id !== currentUser.id" cols="12" md="6">
        <base-selector
          v-model="user.attributes.roles[1]"
          :label="$tc('common.category', 1) + '(*)'"
          :items="
            userCategories.map(c => ({
              text: $tc(`user.${c.value}`, 1),
              value: c.value
            }))
          "
      /></v-col>

      <v-col cols="12" md="6">
        <user-group-selector
          :user="user"
          :label="$tc('userGroup.company', 1) + '(*)'"
          :group-id="groupId"
      /></v-col>
      <v-col cols="12" md="6">
        <base-field-input
          v-model="user.attributes.info.companyPosition"
          :label="$t('user.companyPosition')"
      /></v-col>
    </v-row>
    <v-btn
      v-if="user.id"
      text
      class="text-capitalize mt-3"
      color="primary"
      :disabled="!isAdmin && user.id !== currentUser.id"
      @click="$emit('reset:password')"
      ><v-icon left>mdi-account-key</v-icon>Reset Password</v-btn
    >
    <router-view />
    <v-divider class="my-4"></v-divider>
    <div v-if="user.attributes.info.address">
      <div class="my-3 text-h6">{{ $t('common.address') }}</div>

      <base-field-input
        v-model="user.attributes.info.address.street"
        :label="$t('common.street')"
      />

      <base-field-input
        v-model="user.attributes.info.address.country"
        :label="$t('common.country')"
      />

      <base-field-input
        v-model="user.attributes.info.address.state"
        :label="$t('common.state')"
      />
      <v-row dense>
        <v-col>
          <base-field-input
            v-model="user.attributes.info.address.city"
            :label="$t('common.city')"
          />
        </v-col>
        <v-col>
          <base-field-input
            v-model="user.attributes.info.address.zipcode"
            :label="$t('common.zipcode')"/></v-col
      ></v-row>
    </div>
    <h6 class="text-h6 mb-5">{{ $tc('document.attachment', 2) }}</h6>
    <base-file-input />
  </div>
</template>

<script>
import { USER_CATEGORIES } from '@/common/terms.js'
import UserGroupSelector from './UserGroupSelector'
import PhoneNumberInput from './PhoneNumberInput'
import { mapGetters } from 'vuex'
export default {
  components: {
    PhoneNumberInput,
    UserGroupSelector
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    groupId: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: 'Supplier'
    }
  },
  data() {
    return {
      place: { name: 'Valencia' },
      userCategories: USER_CATEGORIES,
      emailRules: [
        v => !!v || `E-mail ${this.$t('hints.isRequired')}`,
        v =>
          /[^@]+@[^.]+\..*/.test(v) || `E-mail ${this.$t('hints.mustBeValid')}`
      ]
    }
  },

  computed: {
    isSupplier() {
      return this.role === 'supplier'
    },
    ...mapGetters({ currentUser: 'auth/currentUser', isAdmin: 'auth/isAdmin' })
  }
}
</script>
