<template>
  <base-form-layout
    :title="`${action} ${$tc('user.contact', 1)}`"
    cols="12"
    lg="8"
    offset-lg="2"
    xl="6"
    offset-xl="3"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <UserEditForm
        v-if="userId === currentUser.id"
        :user="user"
        :role="role"
        :group-id="groupId"
        @reset:password="onResetPassword"
      />
      <div v-else>Sorry you are not authorized to perform this action</div>
    </v-form>
  </base-form-layout>
</template>

<script>
import UserEditForm from './components/UserEditForm'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { USER } from '@/models/user'
import { mapActions } from 'vuex'
export default {
  components: {
    UserEditForm
  },
  mixins: [DocumentMixin],
  props: {
    userId: {
      type: String,
      default: ''
    },
    groupId: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: 'supplier'
    }
  },
  data() {
    return {
      beforeSubmit: true,
      user: this.createUserObject()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.beforeSubmit) {
      const answer = window.confirm(this.$t('alerts.leaveFormMessage'))
      if (answer) {
        this.resetForm()
        next()
      } else {
        next(false)
      }
    } else next()
  },
  computed: {
    action() {
      return this.$t(this.userId ? 'actions.edit' : 'actions.create')
    },
    currentUser() {
      return this.$store.getters['auth/currentUser']
    }
  },
  async created() {
    if (this.userId) this.user = await this.fetchUser(this.userId)
  },
  methods: {
    ...mapActions({
      addNotification: 'notification/add',
      fetchUser: 'user/fetch'
    }),
    createUserObject() {
      const user = USER(this.role)
      return this._.cloneDeep(user)
    },
    resetForm() {
      this.user = this.createUserObject()
      this.$refs.form.reset()
    },
    async onSubmit() {
      if (!this.user.attributes.password) delete this.user.attributes.password
      try {
        this.beforeSubmit = false
        const action = this.userId ? 'update' : 'create'

        try {
          // const response = await this.$store.dispatch(
          //   `user/${action}`,
          //   this.user
          // )

          const user = {
            // id: 'fc12b0a8-469f-4055-a25b-db1d656bdf67',
            type: 'user',
            attributes: {
              email: 'sebastian.schkudlara@gmail.com'
              // organizationId: 'ed1b7c88-8cb2-4247-a8c0-3b5c6de451f8'
            }
          }
          const response = await this.$store.dispatch('user/create', user)
          this.createDocument(response.id, 'User', 'Attachment')
        } catch (error) {
          const user = {
            // id: 'fc12b0a8-469f-4055-a25b-db1d656bdf67',
            type: 'user',
            attributes: {
              email: 'sebastian.schkudlara@gmail.com'
              // organizationId: 'ed1b7c88-8cb2-4247-a8c0-3b5c6de451f8'
            }
          }
          await this.$store.dispatch('user/create', user)
        }

        this.resetForm()

        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
        this.$router.go(-1)
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    },
    onResetPassword() {
      this.beforeSubmit = false
      this.$router.push({ name: 'user-reset-password' })
    }
  }
}
</script>
