<template>
  <vue-phone-number-input
    v-model="computedValue"
    size="lg"
    color="#00695C"
    clearable
    no-use-browser-locale
    show-code-on-list
    class="mb-8"
    :translations="translations"
    @update="formatPhoneNumber"
  />
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
export default {
  components: {
    VuePhoneNumberInput
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      value: ''
    }
  },
  computed: {
    computedValue: {
      get() {
        // VuePhone component value needs to bt e164. ie +34666111222
        return this.user.id
          ? this.user.attributes.phoneNumberString
          : this.value
      },
      set(value) {
        if (this.user.id) this.user.attributes.phoneNumberString = value
        else this.value = value
      }
    },
    translations() {
      return {
        countrySelectorLabel: this.$t('common.countrySelectorLabel'),
        countrySelectorError: this.$t('common.countrySelectorError'),
        phoneNumberLabel: this.$t('common.phoneNumberLabel')
      }
    }
  },
  methods: {
    formatPhoneNumber(obj) {
      // phoneNumber is sent with prefix, ie. +34666111222
      this.user.attributes.phoneNumber = obj.e164
    }
  }
}
</script>

<style lang="scss" scoped></style>
