<template>
  <v-autocomplete
    v-model="user.attributes.groupIds"
    v-bind="$attrs"
    outlined
    :items="userGroups"
    item-text="attributes.name"
    item-value="id"
    multiple
  ></v-autocomplete>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config'
export default {
  inheritAttrs: false,

  props: {
    user: {
      type: Object,
      required: true
    },
    groupId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userGroups: []
    }
  },
  async created() {
    if (this.groupId) this.user.attributes.groupIds.push(this.groupId)
    this.userGroups = await this.filterUserGroups()
  },
  methods: {
    async filterUserGroups() {
      return await this.$store.dispatch('userGroup/filter', {
        organizationId: ORGANIZATION_ID()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
